import store from '@/store';

export const getPrimaryColor = () => {
  let embedSettings = store.getters['embedSettings'];
  if (!embedSettings || !embedSettings.primary_color) {
    return '#5D27D0';
  }
  return embedSettings.primary_color;
};

export const getSecondaryColor = () => {
  let embedSettings = store.getters['embedSettings'];
  if (!embedSettings || !embedSettings.secondary_color) {
    return '#FFFFFF';
  }
  return embedSettings.secondary_color;
};

export const getFontColor = () => {
  let embedSettings = store.getters['embedSettings'];
  if (!embedSettings || !embedSettings.font_color) {
    return '#26262E';
  }
  return embedSettings.font_color;
};

export const getPlayerEpisodesPrimaryColor = () => {
  let embedSettings = store.getters['embedSettings'];
  if (!embedSettings || !embedSettings.player_episodes_primary_color) {
    return '';
  }
  return embedSettings.player_episodes_primary_color;
};

export const getPlayerEpisodesSecondaryColor = () => {
  let embedSettings = store.getters['embedSettings'];
  if (!embedSettings || !embedSettings.player_episodes_secondary_color) {
    return '';
  }
  return embedSettings.player_episodes_secondary_color;
};

export const getPlayerEpisodesFontColor = () => {
  let embedSettings = store.getters['embedSettings'];
  if (!embedSettings || !embedSettings.player_episodes_font_color) {
    return '';
  }
  return embedSettings.player_episodes_font_color;
};

/////////////////////////////////////////////////////////////////////
// Change hex color into RGB /////////////////////////////////////////////////////////////////////
export const getRGBColor = (hex, type) => {
  let color = hex.replace(/#/g, '');
  // rgb values
  var r = parseInt(color.substr(0, 2), 16);
  var g = parseInt(color.substr(2, 2), 16);
  var b = parseInt(color.substr(4, 2), 16);

  return `--color-${type}: ${r}, ${g}, ${b}`;
};

/////////////////////////////////////////////////////////////////////
// Determine the accessible color of text
/////////////////////////////////////////////////////////////////////
export const getAccessibleColor = hex => {
  let color = hex.replace(/#/g, '');
  // rgb values
  var r = parseInt(color.substr(0, 2), 16);
  var g = parseInt(color.substr(2, 2), 16);
  var b = parseInt(color.substr(4, 2), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? '#000000' : '#FFFFFF';
};
